.static-view {
	margin-top: var(--page-margin-top);


	.Grosenbrode-units {
		margin: 40px 0;

		.single-unit {
			margin-bottom: 30px;
		}
	}

	&.Infos-us-v1 {
		ol {
			padding-left: 20px;

			ul {
				list-style: disc;
				padding-left: 25px;
				margin: 10px 0 30px 0;

				li {
					margin-bottom: 10px;

					@media (max-width: 767px) {
						word-break: break-word;
					}

				}
			}
		}
	}
}